import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import "./Subsidiaries.scss";

function Subsidiaries() {
	return (
		<Container id="subsidiaries" className="py-10 my-10">
			<h2 className="text-center text-white mb-4">Our Subsidiaries</h2>

			<p className="lead text-center text-gray-3 mb-5">
				Discover the range of services and innovations offered by our subsidiaries.
			</p>

			<Row className="justify-content-center">
				<Col md={4} className="mb-4">
					<Card className="border-gray-2 p-1" bg="background">
						<Card.Body>
							<Card.Title className="text-gray-4">
								Elevoppdrag
							</Card.Title>

							<Card.Text as="h2" className="text-white">
								Connecting Students with Opportunities
							</Card.Text>

							<ul className="list-unstyled subsidiaries py-1">
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									Workplace Learning Programs
								</li>
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									Business-Education Collaboration
								</li>
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									Vocational Student Job Listings
								</li>
							</ul>

							<Link to="https://elevoppdrag.no">
								<Button variant="outline-secondary">Learn More</Button>
							</Link>
						</Card.Body>
					</Card>
				</Col>

				<Col md={4} className="mb-4">
					<Card className="border-secondary p-1" bg="background">
						<Card.Body>
							<Card.Title className="text-gray-4">
								Bærekraftsregisteret
							</Card.Title>

							<Card.Text as="h2" className="text-white">
								Automating Sustainability Reporting
							</Card.Text>

							<ul className="list-unstyled subsidiaries py-1">
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									Centralized Sustainability Reports
								</li>
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									Supply Chain Data Integration
								</li>
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									AI-Powered Analysis
								</li>
							</ul>

							<Link to="https://bkreg.no">
								<Button variant="secondary">Learn More</Button>
							</Link>
						</Card.Body>
					</Card>
				</Col>

				<Col md={4} className="mb-4">
					<Card className="border-gray-2 p-1" bg="background">
						<Card.Body>
							<Card.Title className="text-gray-4">
								Nautila Tech
							</Card.Title>

							<Card.Text as="h2" className="text-white">
								Innovative Tech Solutions for Businesses
							</Card.Text>

							<ul className="list-unstyled subsidiaries py-1">
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									Custom Software Development
								</li>
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
									Design and Branding
								</li>
								<li>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="text-secondary"
									/>{" "}
								Hosting & IT Maintenance
								</li>
							</ul>

							<Link to="https://nautila.tech">
								<Button variant="outline-secondary">Learn More</Button>
							</Link>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Subsidiaries;
