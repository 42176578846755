import React, { useState } from "react";
import "./App.scss";

import Footer from "../components/Footer";
import Header from "../components/Header";
import {
	Button,
	Container,
	Row,
	Col,
	Form,
	Alert,
	Spinner,
} from "react-bootstrap";

function ContactUsPage() {
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		subject: "",
		message: "",
	});
	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [success, setSuccess] = useState(false);

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setSuccess(false);

		const url =
			"https://script.google.com/macros/s/AKfycby1cT60blv3N_aVabyvTjUfv2W3IP3_PTof0d207LbrNnmWn2qcf8zS9w4LEcdd9Xz32g/exec";
		const formBody = new URLSearchParams(formData).toString();

		try {
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				body: formBody,
			});

			if (response.ok) {
				setSuccess(true);
				setSubmitted(true);
			} else {
				console.error("Form submission error");
			}
		} catch (error) {
			console.error("Form submission error", error);
		}

		setLoading(false);
	};

	return (
		<div className="bg-background">
			<div className="hero-gradient pt-3">
				<Header />
				<Container id="contact" className="pt-10 mt-10">
					<Row className="justify-content-center">
						<Col md={8} lg={6}>
							<h1 className="mb-4 text-white text-center">Contact Us</h1>
						</Col>
					</Row>
				</Container>
			</div>

			<Container className="pb-10 mb-10">
				<Row className="justify-content-center">
					<Col md={8} lg={6}>
						{!submitted ? (
							<Form onSubmit={handleSubmit}>
								<Form.Group controlId="formName" className="form-group mb-4">
									<Form.Label className="label bg-background text-gray-3">
										name
									</Form.Label>
									<Form.Control
										className="input text-white"
										type="text"
										placeholder="Enter your name"
										name="name"
										value={formData.name}
										onChange={handleChange}
										required
									/>
								</Form.Group>

								<Form.Group controlId="formEmail" className="form-group mb-4">
									<Form.Label className="label bg-background text-gray-3">
										email
									</Form.Label>
									<Form.Control
										className="input text-white"
										type="email"
										placeholder="Enter your email"
										name="email"
										value={formData.email}
										onChange={handleChange}
										required
									/>
								</Form.Group>

								<Form.Group controlId="formSubject" className="form-group mb-4">
									<Form.Label className="label bg-background text-gray-3">
										subject
									</Form.Label>
									<Form.Control
										className="input text-white"
										type="text"
										placeholder="Enter a subject"
										name="subject"
										value={formData.subject}
										onChange={handleChange}
										required
									/>
								</Form.Group>

								<Form.Group controlId="formMessage" className="form-group mb-4">
									<Form.Label className="label bg-background text-gray-3">
										message
									</Form.Label>
									<Form.Control
										className="input text-white"
										as="textarea"
										rows={5}
										placeholder="Enter your message"
										name="message"
										value={formData.message}
										onChange={handleChange}
										required
									/>
								</Form.Group>

								<div className="text-center">
									<Button variant="secondary" type="submit" disabled={loading}>
										{loading ? (
											<>
												<Spinner
													as="span"
													animation="border"
													size="sm"
													role="status"
													aria-hidden="true"
												/>
												<span className="sr-only"> Loading...</span>
											</>
										) : (
											"Send"
										)}
									</Button>
								</div>
							</Form>
						) : (
							success && (
								<Alert variant="success" className="text-center">
									Your message has been sent successfully!
								</Alert>
							)
						)}
					</Col>
				</Row>
			</Container>

			<div className="footer-gradient">
				<Footer />
			</div>
		</div>
	);
}

export default ContactUsPage;
