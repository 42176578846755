import React from "react";
import { Row, Container } from "react-bootstrap";
import Slider from "react-infinite-logo-slider";
import Oslo from "../../assets/Oslo.svg";
import NAV from "../../assets/NAV.svg";
import Nautila from "../../assets/Nautila.svg";
import Flip from "../../assets/Flip.svg";
import Styretavla from "../../assets/Styretavla.svg";
import "./Partners.scss";
import { Link } from "react-router-dom";

function Partners() {
	return (
		<Container id="partners" className="py-10 my-10">
			<Container className="text-center mb-6">
				<h2 className="text-white mb-4">A Trusted Partner</h2>
				<p className="lead text-gray-3 mb-5">
					Our team has experience working on projects from these great companies
					through Nautila Tech
				</p>
			</Container>

			<div className="fade-partners">
				<Slider width="25rem" duration={40}>
					<Slider.Slide className="slider">
						<Link to="https://apps.apple.com/no/app/min-start">
							<img src={Oslo} alt="Oslo logo" className="logo" />
						</Link>
					</Slider.Slide>

					<Slider.Slide className="slider">
						<Link to="https://www.nautila.tech/">
							<img src={Nautila} alt="Nautila logo" className="logo" />
						</Link>
					</Slider.Slide>

					<Slider.Slide className="slider">
						<Link to="https://flip.no/">
							<img src={Flip} alt="Flip logo" className="logo" />
						</Link>
					</Slider.Slide>

					<Slider.Slide className="slider">
						<Link to="https://www.nav.no/">
							<img src={NAV} alt="NAV logo" className="logo" />
						</Link>
					</Slider.Slide>

					<Slider.Slide className="slider">
						<Link to="https://styretavla.no/">
							<img src={Styretavla} alt="Styretavla logo" className="logo" />
						</Link>
					</Slider.Slide>
				</Slider>
			</div>
		</Container>
	);
}

export default Partners;
