import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./News.scss";

function News() {
	const [newsItems, setNewsItems] = useState([]);

	useEffect(() => {
		fetch("/news-data.json")
			.then((response) => response.json())
			.then((data) => setNewsItems(data.articles))
			.catch((error) => console.error("Error loading news data:", error));
	}, []);

	return (
		<Container id="news" className="py-10 my-10">
			<h2 className="text-center text-white mb-4">News</h2>
			<p className="lead text-center text-gray-3 mb-5">
				Stay up to date with Mandal Group
			</p>
			<Row id="news" xs={1} md={2} lg={4} className="mt-4 g-4">
				{newsItems.slice(0, 4).map((item, index) => (
					<Col key={index} className="d-flex">
						<Card
							data-aos="fade-left"
							data-aos-delay={`${(index + 1) * 200}`}
							data-aos-duration="1000"
							className="flex-fill p-1 d-flex flex-column"
							bg="primary"
						>
							{item.imageUrl && (
								<Card.Img
									variant="top"
									src={item.imageUrl}
									className="p-1"
									alt={`header for ${item.title}`}
								/>
							)}
							<Card.Body className="d-flex flex-column">
								<Card.Title className="text-white">{item.title}</Card.Title>
								<Card.Text className="small text-white">
									{item.summary}
								</Card.Text>
								<Link to={item.link} className="btn btn-secondary mt-auto">
									Read
								</Link>
							</Card.Body>
						</Card>
					</Col>
				))}
			</Row>
		</Container>
	);
}

export default News;
