import React, { useEffect } from "react";
import "./_custom.scss";

import Footer from "./components/Footer";
import Header from "./components/Header";
import { Button, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function NotFoundPage() {
	return (
		<div className="bg-background">
			<div className="hero-gradient pt-3">
				<Header />

				<Container id="notfound" className="py-10 my-10">
					<Row className="text-center">
						<h1 className="mb-4 text-white">Oops! Page Not Found</h1>
						<p className="lead text-gray-3 mb-5">
							It looks like the page you're searching for has taken a vacation.
							But don't worry, we've got plenty of other exciting places for you
							to explore. Let's get you back on track!
						</p>

						<Link to="/">
							<Button variant="secondary" size="lg">
								Back Home
							</Button>
						</Link>
					</Row>
				</Container>
			</div>

			<div className="footer-gradient">
				<Footer />
			</div>
		</div>
	);
}

export default NotFoundPage;
