import NotFoundPage from "./NotFound";
import React, { useState, useEffect } from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useLocation,
} from "react-router-dom";
import aos from "aos";
import "aos/dist/aos.css";

function App() {
	const [routes, setRoutes] = useState([]);

	useEffect(() => {
		async function loadRoutes() {
			const context = require.context("./pages", true, /\.jsx?$/);
			const routes = [];

			for (const key of context.keys()) {
				const filePath = key.replace("./", "");
				const Component = (await context(key)).default;

				const path = `/${filePath.replace(/\.[jt]sx?$/, "")}`
					.replace(/\/index$/, "")
					.replace(/\[([^\]]+)\]/, ":$1");

				routes.push({ path, Component });
			}

			setRoutes(routes);
		}

		loadRoutes();
	}, []);

	useEffect(() => {
		aos.init();
	}, []);

	return (
		<Router>
			<ScrollToTop />
			<Routes>
				{routes.map(({ path, Component }, index) => (
					<Route key={index} path={path} element={<Component />} />
				))}

				<Route path="*" element={<NotFoundPage />} />
			</Routes>
		</Router>
	);
}

function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
}

export default App;
