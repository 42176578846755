import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

import Footer from "../components/Footer";
import Header from "../components/Header";

import "./App.scss";
import "./board.scss";

function Board() {
	return (
		<div className="App bg-background">
			<div className="hero-gradient pt-3 pb-10">
				<Header />
			</div>

			<div>
				<Container id="board-member" className="py-4">
					<h2 className="text-center text-white mb-4">Board</h2>

					<p className="lead text-center text-gray-3 mb-5">
						Discover the vision and leadership guiding Mandal Group.
					</p>

					<Row className="justify-content-center">
						<Col md={6} className="mb-4">
							<Card className="border-gray-2 p-3" bg="background">
								<Card.Body>
									<Card.Title className="text-white text-center">
										Sebastian W. K. P-D. Mandal
									</Card.Title>

									<Card.Text as="h2" className="text-center text-gray-4">
										Founder & CEO
									</Card.Text>

									<Card.Text className="text-gray-3 mt-4">
										Sebastian is the founder and sole board member of Mandal
										Group, a Norwegian company with a core focus on technology,
										innovation, and driving prosperity. With a background in
										full-stack development, UX design, and business strategy, he
										leads projects spanning all across the software industry.
										Sebastian is also pursuing a Bachelor of Science in
										Informatics at the University of Oslo.
									</Card.Text>

									<div className="text-center mt-4">
										<a
											href="https://linkedin.com/in/sebmandal"
											target="_blank"
											rel="noopener noreferrer"
										>
											<Button variant="outline-secondary" className="mx-2">
												<FontAwesomeIcon icon={faLinkedin} /> LinkedIn
											</Button>
										</a>
										<a
											href="https://mandal.group"
											target="_blank"
											rel="noopener noreferrer"
										>
											<Button variant="outline-secondary" className="mx-2">
												<FontAwesomeIcon icon={faGlobe} /> Website
											</Button>
										</a>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="footer-gradient">
				<Footer />
			</div>
		</div>
	);
}

export default Board;
