import React from "react";
import { Container, Row, Button, Col } from "react-bootstrap";
import "../App.scss";
import "./FirstSemesterDownloads.scss";

import Footer from "../../components/Footer";
import Header from "../../components/Header";

function FirstSemesterDownloads() {
	const pdfFiles = [
		{ name: "IN1000: Python for Everyone 2/e", url: "/pdfs/in1000-2nd.pdf" },
		{ name: "IN1000: Python for Everyone 3/e", url: "/pdfs/in1000-3rd.pdf" },
		{
			name: "IN1020: The Architecture of Computer Hardware, Systems Software & Networking",
			url: "/pdfs/in1020.pdf",
		},
	];

	return (
		<div className="App bg-background">
			<div className="hero-gradient pt-3">
				<Header />

				<Container id="hero" className="hero-container pt-10">
					<Row className="text-center">
						<h1 className="mb-4 text-white">
							One Hundred Percent <span className="text-secondary">NOT</span>{" "}
							Pirated Content!
						</h1>
						<p className="lead text-gray-3 mb-5">
							Download all of these ethically sourced PDFs here!
						</p>

						<Col md={6} className="text-center w-100 mb-4 mb-md-0">
							<ul className="download-list d-flex flex-column gap-3 list-unstyled">
								{pdfFiles.map((file, index) => (
									<li key={index} className="text-center">
										<a
											href={file.url}
											download
											className="btn btn-secondary w-100"
										>
											{file.name}
										</a>
									</li>
								))}
							</ul>
						</Col>
					</Row>
				</Container>
			</div>

			<div className="footer-gradient">
				<Footer />
			</div>
		</div>
	);
}

export default FirstSemesterDownloads;
