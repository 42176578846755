import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import "./Footer.scss";
import Logo from "../assets/Logo no Icon.svg";

const Footer = () => {
	return (
		<footer id="footer" className="footer text-white">
			<Container fluid={true} className="text-white">
				<Container className="py-8 mt-5">
					<Row className="align-items-center mb-md-5">
						<Col md={6} className="mb-4 mb-md-0">
							<Link
								className="text-white text-hover-primary text-decoration-none"
								to="/"
							>
								<img src={Logo} alt="logo" className="d-none d-md-block w-50" />
								<img src={Logo} alt="logo" className="d-md-none w-100" />
							</Link>
						</Col>
						<Col md={6} className="d-none d-md-block text-md-end">
							<div className="social-links">
								<a
									href="https://www.linkedin.com/company/mandalgroup/"
									className="text-white"
								>
									<FontAwesomeIcon icon={faLinkedin} size="lg" />
								</a>
							</div>
						</Col>
					</Row>

					<Row className="mt-3 justify-content-between align-items-end">
						<Col md={6} className="mb-4 mb-md-0">
							<div className="resources-nav d-flex flex-row align-items-end">
								<h3 className="resources-text text-white">Resources</h3>
								<ul className="list-unstyled bordered-list mb-0 white">
									<li className="pe-5">
										<Link to="/board">Board & Leadership</Link>
									</li>
									<li className="pe-5">
										<Link to="https://www.linkedin.com/company/mandalgroup/jobs">
											Work at Mandal Group
										</Link>
									</li>
									<li className="pe-5">
										<Link to="/mission">Mission Statement</Link>
									</li>
									<li className="pe-5">
										<Link to="/compliance">GDPR & Governance</Link>
									</li>
									<li className="pe-5">
										<Link to="mailto:contact@mandal.group">
											Report an error
										</Link>
									</li>
								</ul>
							</div>
						</Col>

						<Col md={6} className="text-md-end">
							<div className="footer-legal mb-4 mb-md-0">
								<p className="mb-1">Mandal Group © 2024</p>
								<p>Org.nr: 931 527 401</p>
							</div>

							<div className="d-md-none social-links">
								<a
									href="https://www.linkedin.com/company/mandalgroup/"
									className="text-white"
								>
									<FontAwesomeIcon icon={faLinkedin} size="lg" />
								</a>
							</div>
						</Col>
					</Row>
				</Container>
			</Container>
		</footer>
	);
};

export default Footer;
