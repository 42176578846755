import React, { useEffect } from "react";

import Footer from "../components/Footer";
import Header from "../components/Header";

import Hero from "../components/home/Hero";
import { Container, Row } from "react-bootstrap";

function Portfolio() {
	return (
		<div className="App bg-background">
			<div className="hero-gradient pt-3">
				<Header />

				<Container id="portfolio" className="py-10 my-10">
					<Row className="text-center">
						<h1 className="mb-4 text-white">Our Portfolio</h1>
						<p className="lead text-gray-3 mb-5">
							Our portfolio is currently being assembled. We will publicize it
							shortly. Thank you for your patience.
						</p>
					</Row>
				</Container>
			</div>

			<div className="footer-gradient">
				<Footer />
			</div>
		</div>
	);
}

export default Portfolio;
