import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./Packages.scss";
import sebmandal from "../../assets/sebmandal.png";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function People() {
	return (
		<Container id="people" className="py-8 my-5">
			<Row className="align-items-center">
				<Col md={2} className="">
					<Image
						src={sebmandal}
						roundedCircle
						className="img-fluid mb-2"
						style={{ width: "150px", height: "150px", objectFit: "cover" }}
						alt={`founder image`}
					/>
					<h4 className="text-white">Sebastian Mandal</h4>
					<p className="text-gray-4">Founder and CEO</p>
				</Col>

				<Col md={10}>
					<FontAwesomeIcon
						icon={faQuoteLeft}
						className="text-gray-4 mb-2"
						style={{ fontSize: "32px" }}
					/>

					<p className="large text-white">
						At Mandal Group, I believe in building businesses that make a
						lasting impact. It's not just about growth, but about creating
						meaningful solutions that connect people, drive innovation, and
						shape a better future for humanity. Every startup is an opportunity
						to push boundaries and contribute to something greater.
					</p>
				</Col>
			</Row>
		</Container>
	);
}

export default People;
